.App {
  margin: 5px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}



.nobreak {display: inline;
white-space: nowrap
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.selected {
	background-color: #ede1e1;
}

.timefrom {
	background-color: #8ae234;
}

.timefrom.selected {
	background-color: #4e9a06;
}

.bibfrom {
	background-color: #8ae234;
}

.bibfrom.selected {
	background-color: #4e9a06;
}

.object-id {
	background-color: yellow;
	text-align: right;
}

.object-id.selected {
	background-color: #ebc334;
	text-align: right;
}

.pagination {
	background-color: lightgrey;
	text-align: right;
}

.pagination.selected {
	background-color: darkgrey;
	text-align: right;
}

.bib {
	font-weight: bold;
	text-align: right;
}

.time {
	background-color: cornsilk;
}

.time {
	background-color: cornsilk;
}

.time.selected {
	background-color: #ede1e1;
}

.runner {
	background-color: white;
}

.runner.selected {
	background-color: #ede1e1;
}

.selectedapplication  {
	background-color: #ede1e1;
}

.applyremains {
	background-color: red;
}

.errorMessage {
	color: red;
}

.buttonLabel {
	padding-left: 3px;
	padding-right: 8px;
}

table, th, td {
  border-bottom: 1px solid black;
  border-collapse: collapse;
  padding: 3px;
}

.okscan {
	background-color: #4CAF50; /* Green */
	border: none;
	color: white;
	padding: 15px 32px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 32px;
	vertical-align: top;
}

.cancelscan {
	background-color: red; /* Green */
	border: none;
	color: white;
	padding: 15px 32px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 16px;
	vertical-align: top;
}

.registertime {
	background-color: #4CAF50; /* Green */
	border: none;
	color: white;
	padding: 15px 32px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 32px;
}

.resultdisplayrow {
	font-size: 32px;
}

.resultdisplaytable {
	width: 100%;
}

.printresult {
	padding-left: 18px;
	padding-right: 18px;
}

.readinglist td {
	padding-left: 8px;
	padding-right: 8px;
}

#bib {
	width: 60px;
}


/*  Navbar */

/* Style the navigation menu */
.topnav {
  overflow: hidden;
  background-color: #333;
  position: relative;
}

/* Hide the links inside the navigation menu (except for logo/home) */
.topnav #myLinks {
  display: none;
}

/* Style navigation menu links */
.topnav a {
  color: white;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
  display: block;
}

/* Style the hamburger menu */
.topnav a.icon {
  background: black;
  display: block;
  position: absolute;
  right: 0;
  top: 0;
}

/* Add a grey background color on mouse-over */
.topnav a:hover {
  background-color: #ddd;
  color: black;
}

/* Style the active link (or home/logo) */
.active {
  background-color: #04AA6D;
  color: white;
}

.topnav H1 {
	display: inline;
	color: white;
	text-align: center;
}

a.nobreak {display: inline;}

.notpaid {
	background-color: rgb(249, 130, 108);
	font-weight: bold;
}

.printstart {
	padding-left: 18px;
	padding-right: 18px;
	padding-top: 18px;
	padding-bottom: 18px;
}

.printstart td {
	padding-left: 8px;
	padding-right: 8px;		
}

.numberInQueue {
	padding-left: 8px;
	padding-right: 8px;
	padding-top: 2px;
	padding-bottom: 2px;
	background-color: rgb(249, 130, 108);
}

.yearwarning {
	font-weight: bold;
	color: yellow;
}

.syncline {
	background-color: violet;
	font-size: 10px;
	font-weight: bold;
}
.cancelled {
	text-decoration: line-through;
}

.modal {
	position: fixed;
	z-index: 1;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.25);
}

.modal_content {
	background-color: white;
	position: absolute;
	top: 2%;
	left: 5%;
	width: 85%;
	padding: 20px;
	border-radius: 5px;
	border: 2px solid black;
}

.applyformcontainer {
	 padding: 0 10px 30px 10px;
}

.close {
	color: Black;
	float: right;
	font-size: 30px;
}

.close:hover {
	color: grey;
	cursor: pointer;
}


.applytable td {
	border-bottom: none;
}

.applyListTable tr:hover {
	background-color: #ffff99;
}

.tableContainer {
    background-color: #ffffdd;
    border-radius: 5px;
    height: 640px;
    width: 550px;
    padding: 0 10px 10px 10px;
    overflow: scroll;
}

.applyListTable Table {
	overflow: scroll;
} 

.tableContainer Table thead{
	background-color: black;
	color: white;
    position: sticky;
    top: 0px;
    margin: 0 0 0 0;
}

